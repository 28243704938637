@import "colors.scss";

@font-face {
  font-family: "Taager";
  src: url(./assets/styling/Taager-Font/Taager-Regular.ttf);
}
* {
  font-family: "Taager";
}

.btn-primary-brand {
  background-color: $blue-dark;
  color: white;
  display: block;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  border: none;
}

.btn-primary-brand:hover {
  background-color: $blue-dark-hover;
}

.btn-ghost-brand {
    background-color: transparent;
    color: black;
    display: block;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
    outline: none;
    border: 1px solid $blue-dark;
}

.btn-ghost-brand:hover {
    border: 1px solid $blue-dark-hover;
    background-color: #eee;
}

#mysr-form-form-el#mysr-form-form-el#mysr-form-form-el.mysr-form-moyasarForm.mysr-form-fixedWidth {
  margin-left: 10px !important; 
  margin-right: 10px !important;
  max-width: 450px !important;
}