@import './mixins.scss';
/*
* To use design system fonts, just add relevant class to the element desired to be styled
* Note: make sure to only rely on said class not to override it's properties
*/

.display {
  &1--extra-bold {
    @include font(58px, 800, 86px);
    @media (max-width: 576px) {
      @include font(47px, 800, 70px);
    }
  }
  &2--bold {
    @include font(58px, 700, 86px);
    @media (max-width: 576px) {
      @include font(47px, 700, 70px);
    }
  }
}

.heading {
  &1 {
    &--extra-bold {
      @include font(33px, 800, 49px);
      @media (max-width: 576px) {
        @include font(23px, 800, 34px);
      }
    }
    &--bold {
      @include font(33px, 700, 49px);

      @media (max-width: 576px) {
        @include font(23px, 700, 34px);
      }
    }
  }
  &2 {
    &--extra-bold {
      @include font(23px, 800, 34px);
      @media (max-width: 576px) {
        @include font(18px, 800, 27px);
      }
    }
    &--bold {
      @include font(23px, 700, 34px);

      @media (max-width: 576px) {
        @include font(18px, 700, 27px);
      }
    }
  }
}

.body {
  &1 {
    &--bold {
      @include font(18px, 700, 27px);
      @media (max-width: 576px) {
        @include font(16px, 700, 24px);
      }
    }
    &--medium {
      @include font(18px, 500, 27px);
      @media (max-width: 576px) {
        @include font(16px, 500, 24px);
      }
    }
    &--regular {
      @include font(18px, 400, 27px);
      @media (max-width: 576px) {
        @include font(16px, 400, 24px);
      }
    }
  }
  &2 {
    &--bold {
      @include font(16px, 700, 24px);
      @media (max-width: 576px) {
        @include font(14px, 700, 21px);
      }
    }
    &--medium {
      @include font(16px, 500, 24px);
      @media (max-width: 576px) {
        @include font(14px, 500, 21px);
      }
    }
    &--regular {
      @include font(16px, 400, 24px);
      @media (max-width: 576px) {
        @include font(14px, 400, 21px);
      }
    }
  }
}

.caption {
  &1 {
    &--bold {
      @include font(14px, 700, 21px);
      @media (max-width: 576px) {
        @include font(12px, 700, 18px);
      }
    }
    &--medium {
      @include font(14px, 500, 21px);
      @media (max-width: 576px) {
        @include font(12px, 500, 18px);
      }
    }
    &--regular {
      @include font(14px, 400, 21px);
      @media (max-width: 576px) {
        @include font(12px, 400, 18px);
      }
    }
  }
  &2 {
    &--bold {
      @include font(12px, 700, 18px);
    }
    &--medium {
      @include font(12px, 500, 18px);
    }
    &--regular {
      @include font(14px, 400, 18px);
    }
  }
  &3 {
    &--medium {
      @include font(11px, 500, 16.41px);
    }
  }
}
