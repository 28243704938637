@mixin font(
  $fontSize: medium,
  $fontWeight: medium,
  $lineHeight: normal,
  $letterSpacing: normal
) {
  font-family: 'Taager' !important;
  font-size: $fontSize !important;
  font-weight: $fontWeight !important;
  line-height: $lineHeight !important;
  letter-spacing: $letterSpacing !important;
}
